.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10; /* Keep the navbar above other elements */
  }

.navbar-right {
    display: flex;
    align-items: center;
}

.profile-picture {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    border-radius: 50%; /* This makes the image rounded */
    object-fit: cover;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .navbar {
        flex-direction: column;
        align-items: flex-start;
    }

    .comments-label {
        visibility: collapse;

    }
    .navbar-right {
        margin-top: 10px;
    }

    .profile-picture {
        margin-left: 10px;
    }
    
}