.message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
}

.profile-container {
    max-width: 100%; /* Ensure container stretches fully */
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    z-index: 1;
    overflow-x: hidden; /* Ensure no horizontal scroll */
    overflow-y: auto; /* Enable vertical scrolling */
    min-height: calc(100vh - 60px); /* Adjust for navbar */
    box-sizing: border-box; /* Include padding in width */
}

/* Heading */
.profile-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

/* Form container */
.profile-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Span full container */
    margin: auto;
    padding: 10px;
}

/* Group styles */
.profile-group {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* Inline groups for desktop */
.profile-group-inline {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
    flex-wrap: wrap; /* Allow wrapping */
}

.profile-group-inline > div {
    flex: 1 1 calc(50% - 20px); /* 50% width with margin */
    margin: 10px; /* Add spacing between fields */
    display: flex;
    flex-direction: column;
}

/* Labels and Inputs */
label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
    color: #555;
}

input, textarea {
    width: 100%; /* Take full parent width */
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-sizing: border-box;
}

textarea {
    resize: vertical;
    min-height: 100px;
}

/* Save button */
.save-btn {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    width: 100%; /* Full width on small screens */
    max-width: 200px; /* Limit size */
}

.save-btn:hover {
    background-color: #45a049;
}

/* Profile Image Styling */
.image-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.profile-image-preview {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 10px;
}

.profile-image-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
    .profile-container {
        padding: 10px; /* Reduce padding */
    }

    .profile-group-inline {
        flex-direction: column; /* Stack vertically */
    }

    .profile-group-inline > div {
        width: 100%; /* Full width */
        margin-bottom: 10px;
    }

    .save-btn {
        width: 100%; /* Full width */
    }
}
