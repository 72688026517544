/* Ensure sign-up box starts below the NavBar */
.sign-up-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Ensure the box starts from the top of the section */
    padding-top: 80px; /* Adjust according to your NavBar height */
    min-height: calc(100vh - 80px); /* Adjust the height, accounting for NavBar */
    background-color: #f4f4f4;
}

/* Keep the content centered and properly spaced */
.sign-up-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 1200px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 20px;
}

.user-info-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.file-input {
    display: none;
}


.custom-file-upload {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

.custom-file-upload:hover {
    background-color: #0056b3;
}

.image-preview-container {
    margin-top: 10px;
    text-align: center;
}

.profile-image-preview {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.image-preview-container {
    margin-top: 10px;
    text-align: center;
}

.profile-image-preview {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}


.user-info-section label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

.user-info-section input[type="text"],
.user-info-section input[type="email"],
.user-info-section input[type="password"] {
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 300px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.profile-image-preview {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-top: 10px;
}

/* Subscription Section */
.subscription-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
    max-width: 800px;
}

.plan {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 45%;
    background-color: #f9f9f9;
}

.plan h3 {
    margin-bottom: 10px;
}

.plan ul {
    list-style: none;
    padding: 0;
    margin-bottom: 10px;
}

.plan ul li {
    margin-bottom: 8px;
}

.plan button {
    padding: 10px 20px;
    border: none;
    background-color: #007BFF;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.plan button.selected {
    background-color: #4CAF50;
}

.login-info-section input {
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 400px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-info-section input {
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 300px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.sign-up-btn {
    padding: 10px 20px;
    margin-bottom: 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.sign-up-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.google-signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.google-signup-label {
    margin-bottom: 15px;
    font-size: 16px;
    color: #333;
}
.google-signup-btn {
    margin-top: 10px;
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
    .subscription-section {
        flex-direction: column;
    }

    .plan {
        width: 100%;
        margin-bottom: 20px;
    }

    .sign-up-content {
        width: 95%;
    }
}
