.message-board-container {
    width: 90%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.board-title {
    text-align: center;
    font-size: 2rem;
    color: #4a90e2;
    margin-bottom: 20px;
}

.add-post-container {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.post-title-input, .post-message-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.post-message-input {
    height: 100px;
}

.add-post-button {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.add-post-button:hover {
    background-color: #357ab8;
}

.posts-container {
    margin-top: 20px;
}

.post-item {
    background-color: #fff;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.post-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.post-title {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 5px;
}

.post-text {
    margin-top: 10px;
    font-size: 1rem;
    color: #333;
}

.expand-post-button, .reply-button {
    padding: 5px 10px;
    font-size: 0.9rem;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.expand-post-button:hover, .reply-button:hover {
    background-color: #357ab8;
}

.replies-container {
    margin-top: 15px;
    /* Ensure replies are visually separated */
    padding-left: 20px; /* This adds default indentation for replies */
    border-left: 2px solid #e0e0e0;
}

.reply-input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 10px;
}

.reply-item {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
    margin-left: 20px; /* Indent for nested replies */
    border-left: 2px solid #e0e0e0;
}

.reply-item strong {
    display: block;
    margin-bottom: 5px;
}

