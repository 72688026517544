/* Main container for the chat page */
.chat-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 96px);
    overflow: hidden; /* Prevent the entire window from scrolling */
}

/* Tabs container */
.chat-tabs {
    display: flex;
    justify-content: flex-start;
    padding: 10px 0;
}

/* Individual chat tabs */
.chat-tab {
    padding: 10px 20px;
    background-color: #f1f1f1;
    border: none;
    cursor: pointer;
    margin-right: 5px;
}

.chat-tab.active {
    background-color: #007bff;
    color: white;
}

/* Chat body to contain the message section and the user list */
.chat-body {
    display: flex;
    flex-direction: row;
    flex-grow: 1; /* Takes up the remaining space after the tabs */
    overflow: hidden; /* Prevents scrolling of the entire chat body */
}

/* Message section containing the messages and input area */
.message-section {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: hidden; /* Hidden to prevent the section from scrolling independently */
}

/* Message display area */
.message-display {
    flex-grow: 1;
    overflow-y: auto; /* Enable scrolling for the messages */
    background-color: #f9f9f9;
    padding: 10px;
    border-right: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Ensures new messages appear at the bottom */
}

/* Styling for the messages */
.message {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #ecf3fb; 
    background-color: #f1f1f1; /* Default background for messages */
    text-align: left; /* Ensure all messages are left-aligned */
}

/* Messages from the current user (You) */
.my-message {
    background-color: #ecf3fb; /* Light blue background for your own messages */
}


/* Message input area */
.message-input-container {
    display: flex;
    flex-direction: row; /* Keep the row layout */
    align-items: flex-end; /* Aligns button to the bottom of the container */
    padding: 10px;
    background-color: #ffffff;
    border-top: 1px solid #ccc;
    position: sticky;
    bottom: 0;
}

/* Message input box */
.message-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;  /* Prevents resizing of the textarea */
    overflow-y: auto;
}

/* Send button */
.send-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-left: 10px;
    border-radius: 4px;
    cursor: pointer;
    flex-shrink: 0;
}

/* Hover effect for the send button */
.send-button:hover {
    background-color: #0056b3;
}

/* User list on the right */
.user-list {
    flex-shrink: 0;
    overflow-y: auto;
    padding: 20px;
    background-color: #f1f1f1;
    border-left: 1px solid #ddd;
    width: 150px;
}

/* Individual user list items */
.user-list-item {
    margin-bottom: 5px;
    padding: 5px 10px;
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid #ddd;
    flex-shrink: 0;
}

/* Media query for screens with width less than 768px */
@media (max-width: 768px) {
    .chat-body {
        flex-direction: column;
    }

    .user-list {
        order: -1; /* Move user list to the top */
        width: 100%;
        border-left: none;
        border-bottom: 1px solid #ddd;
    }

    .message-section {
        flex: 1;
        order: 1; /* Ensure the message section is below the user list */
    }

    .message-input-container {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: white;
        padding: 10px;
        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    }

    .message-display {
        padding-bottom: 72px; /* Ensure there's space above the input box */
        overflow-y: auto; /* Ensure scrolling works in mobile view */
    }
}
