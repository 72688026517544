/* General container styling */
.meeting {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .meeting h1 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
  }
  
  .meeting p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 10px;
    color: #555;
  }
  
  .meeting p strong {
    color: #333;
  }
  
  /* Video container */
  .video-container {
    width: 100%;
    height: 400px;
    background-color: #000;
    border-radius: 8px;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
  }
  
  .video-container video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Join button styling */
  .join-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
    margin: 0 auto;
    display: block;
  }
  
  .join-button:hover {
    background-color: #0056b3;
  }
  
  .join-button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  
  /* Success message */
  .meeting p.success-message {
    font-size: 1.2rem;
    color: #28a745;
    text-align: center;
    margin-top: 20px;
  }
  
  /* Error message */
  .error {
    color: #dc3545;
    font-size: 1rem;
    text-align: center;
    margin-top: 10px;
  }
  
  /* Responsive design */
  @media (max-width: 600px) {
    .meeting {
      padding: 15px;
    }
  
    .meeting h1 {
      font-size: 1.5rem;
    }
  
    .join-button {
      font-size: 0.9rem;
      padding: 8px 15px;
    }
  
    .video-container {
      height: 300px;
    }
  }
  