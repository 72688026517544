.onboarding-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center; /* Centers vertically */
    justify-content: center; /* Centers horizontally */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  }
  
  .modal {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 400px; /* Set a fixed width */
    max-height: 500px; /* Limit the height */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
    position: relative;
  }
  
  .textarea-input {
    width: 100%;
    height: 100px; /* Set a fixed height for the textarea */
    margin: 15px 0;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  .modal-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .modal-button:hover {
    background-color: #45a049;
  }
  