/* Upcoming Meetings Container */
.upcoming-meetings-container {
  width: 100%;
  padding: 20px;
  text-align: center;
}

/* Create Meeting Form */
.create-meeting-form {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
}

/* Meetings List */
.meetings-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.meetings-list .meeting-item {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  text-align: left;
}

.meetings-list .meeting-item p {
  margin: 5px 0;
  color: #666;
}

.create-meeting-form input,
.create-meeting-form textarea,
.create-meeting-form button {
  display: block;
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.create-meeting-form button {
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.create-meeting-form button:hover {
  background-color: #0056b3;
}
