
html, body {
  margin: 0;
  padding: 0;
  max-width: 100%;
  overflow-x: hidden; /* Disable horizontal scrolling */
  overflow-y: auto !important; /* Enable vertical scrolling */
  height: 100%; /* Allow full-height content to scroll */
}

* {
  box-sizing: border-box; /* Ensure consistent padding/margins */
}

nav {
  position: relative; /* Add position to control stacking context */
  z-index: 1000; /* Set a high value for nav to always appear above others */
}

button {
  position: relative; /* Ensure buttons are in the document flow */
  z-index: 1; /* Ensure buttons are above other elements */
}

.modal {
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none; /* Hidden by default */
  pointer-events: none; /* Prevent interactions when hidden */
}

.modal.active {
  display: flex; /* Show modal */
  pointer-events: auto; /* Allow interactions */
}
.modal-content {
  position: relative;
  z-index: 1060;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}
