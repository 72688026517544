/* Ensure sign-up box starts below the NavBar */
.login-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Ensure the box starts from the top of the section */
    padding-top: 80px; /* Adjust according to your NavBar height */
    min-height: calc(100vh - 80px); /* Adjust the height, accounting for NavBar */
    background-color: #f4f4f4;
}

/* Keep the content centered and properly spaced */
.login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 1200px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 20px;
}

.login-title {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.login-info-section input {
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 400px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-info-section input {
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 300px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-btn {
    padding: 10px 20px;
    margin-bottom: 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.login-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.google-signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.google-signup-label {
    margin-bottom: 15px;
    font-size: 16px;
    color: #333;
}
.google-signup-btn {
    margin-top: 20px;
}

.sign-up-label {
    margin-top: 15px;
    font-size: 16px;
    color: #333;
}

.custom-link {
    color: #1a0dab; /* Google blue color for links */
    text-decoration: underline;
    cursor: pointer;
  }
  
  .custom-link:hover {
    color: #551a8b; /* Darker color on hover */
    text-decoration: underline;
  }
